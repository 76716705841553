import strategy from "../assets/whatwedo/howitstarted.png";
import diagram from "../assets/about/diagram.png";
import logobayadcard from "../assets/logo/bayadpayduns.png";
import bayadcarddunsdict from "../assets/logo/bayadcarddunsdict.png";

import team from "../data/teamdb.js";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const Aboutus = ()=>{
    useEffect(() => {
        const page = document.getElementById('aboutuspage');
        page.scrollIntoView({behavior:'smooth',block:'start'});
    });


//   SSL_CRT_FILE=./.cert/cert.pem SSL_KEY_FILE=./.cert/key.pem
    return(
        <div id="aboutuspage" className= " m-5  aboutuspage ">
            <div className="aboutusheader">
                <h1 className="mb-5 text-uppercase text-center aboutusourcompanyprofile">Our Company Profile</h1>

            </div>
            <div className="aboutuscontent">
                <div className=" aboutpagecard  ">
                    <div className="row p-0 m-0 g-0">
                        <div className="col-12 col-lg-4">
                            <img src={strategy} className="img-fluid" alt="company profile"/>
                            <img src={bayadcarddunsdict} className="img-fluid" alt="logo"/>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="card-body mt-3">
                                <h3 className="text-uppercase mb-3 aboutuscardtitle card-title ">How It All Started</h3>
                                <p className="card-text aboutustext">
                                            euodoó Technologies, inc. is an outcome of more than fifteen (15) years of 
                                            extensive experience within the Information Technology (IT) Solutions and 
                                            Services industry. Formerly Innovare Tekhne, the spin-off company now carries 
                                            the Systems Integrator (SI) products and services while Innovare will focus on 
                                            IT-managed services. <br/> <br></br>

                                            The firm provides comprehensive IT Solutions focusing on areas such as 
                                            Enterprise Content Management (ECM), Enterprise Resource Planning (ERP), Governance, 
                                            Risk Management and Compliance (GRC), Anti-Money Laundering (AML) system, Integrated Cloud 
                                            School Management system, Human Resource Information System (HRIS), Automated Bus Ticketing 
                                            System (ABTS), customized software development and outsourced IT solutions and services, 
                                            both in public and private entities.             

                                            With combined expertise of its management and executives, highly-skilled IT professionals and 
                                            reliable solution partners, the firm prides itself in delivering technical proficiency and
                                            competency that cut across different segments such as Information and Communication Technology
                                            (ICT), Finance and Accounting, Sales and Marketing, Operations, and Management. As a result, 
                                            euodoó Tech has secured accreditation from Dun & Bradstreet (D&B) and this is a confirmation 
                                            of the company’s compliance with the requirements of a Vendor Integrity Access (VIA). <br/> <br></br>

                                            euodoó Tech’s viewpoint, “Building Technologies. Inspiring Innovations.”, follows a distinctive 
                                            and strategic approach to understand and assess essential IT solutions that will ultimately allow 
                                            our clients to gain competitive advantage. The company believes that the success and efficiency of 
                                            every IT solution is tantamount to the mutual and collaborative effort of both the solutions provider 
                                            and the client. We, as a partner to our clients, are very receptive to the ever-changing technology 
                                            landscape and the business’ competitive field. <br/> <br></br>

                                            eeuodoó Tech’s way of providing service is anchored to excellence, innovation and commitment to its 
                                            potential and existing clientele, thus nurturing long-standing business relationship. <br/> <br></br>                       
                                </p>
                            </div>
                        </div>
                    </div> 
                    <div className="row p-0 m-0 g-0"> 
                        <div className="col-12 col-lg-6">
                            <div className="card-body mt-3">
                                <div className=" aboutustext">
                                    <h4 className="card-text py-4 text-uppercase text-center aboutusourcompanyprofile">What we do best</h4>
                                    euodoó Tech utilizes a wide-range of software solutions and technical consulting services 
                                    putting in play our strategic know-how and understanding of the key technology drivers from 
                                    planning, development, implementation up to integration of advanced technologies into the client’s
                                    business, providing scalable architecture that enables them to gain competitive advantage. <br/> <br></br>

                                    The company’s philosophy, “Building Technologies. Inspiring Innovations.”, trails its 
                                    value proposition to provide a distinguished and effective method to understand the need of an IT 
                                    solution that matches its client requirement and ultimately establish a strategic edge in servicing 
                                    their respective clientele. It follows a methodological approach in assessment, identification of gap 
                                    and opportunities, transition from design to implementation and up to monitoring and evaluation.
                                </div>
                            </div>
                        </div>
                        <div className="col-12  col-lg-6">
                            <div className="card-body mt-3">
                                <img src={diagram} className=" img-fluid" alt="diagram"/>
                            </div>
                        </div>
                    </div>
                    <div className="row p-0 m-0 g-0"> 
                        <div className="col-12">
                            <h4 className=" text-uppercase mb-3 pt-5 text-center aboutusourcompanyprofile">Meet The Team</h4>
                        </div>
                    </div>

                    <div type="button" className="row py-5 m-0 g-0"> 
                    
                            {
                                team.map((teams,index)=>{
                                    return(
                                        <div key={index}  className="col-12 col-lg-4 col-md-6">
                                            <Link to={`/team/${index}`} style={{'textDecoration': 'none'}}>
                                                <div className="card mb-3 mx-auto teamcard" >
                                                    <div className="row g-0 p-3">
                                                        <div className="  col-4 ">
                                                            <img src={teams.Image} className="img-fluid" alt="diagram"/>
                                                        </div>
                                                        <div className="col-8 ">
                                                            <div className="card-body pt-1">
                                                                <p className="card-title text-center"><b>{teams.name}</b></p>
                                                                <p className="card-text text-center" style={{lineHeight:"1.2"}}>
                                                                    {teams.role}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                        
                                        </div>
                                    )
                                        
                                        
                                })
                            }
                    
                    </div>
                </div>

                <section className='w-full'>
                    <h1 className="mb-5 mt-5 text-uppercase text-center aboutusourcompanyprofile">
                        Our Location</h1>
                    
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.8897445885077!2d121.04458397476544!3d14.548297085931877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8efc5c2efa1%3A0xe66f27cbb9b1a126!2seuodo%C3%B3%20Technologies%2C%20Inc.!5e0!3m2!1sen!2sph!4v1688538371763!5m2!1sen!2sph" 
                        style={{border:0, width: '100%', height: '350px'}} allowFullScreen="" loading="lazy">
                    </iframe>
                    {/* <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=10/f one global place 25th street cor. 5th avenue bonifacio global city&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><a href="https://embedgooglemap.2yu.co/">html embed google map</a></div></div> */}
                </section>          
            </div>

        </div>
    )
}

export default Aboutus;




