
// import ReactPlayer from 'react-player/youtube'
import phitex2023 from "../assets/logo/phitex2023.png"

import milestone from "../assets/milestone/milestonetransparent.png";

import strategy from "../assets/whatwedo/howitstarted.png";
import bayadcarddunsdict from "../assets/logo/bayadcarddunsdict.png";

// all in a day news/article
import fms from "../assets/news/article/fms1.jpg";
import fms2 from "../assets/news/article/fms2-t.png";
import mainpic from "../assets/announcement/PASANGMASDA/mainpics.jpg";
// import guidetofms from "../assets/news/article/mainpics.jpg";
import jeepney from "../assets/news/launching/pm_jeepney_launching1.png";
import fb from "../assets/news/videos/NewsLive.mp4";
import euodoonews from "../assets/news/videos/NewsVid.mp4";


import clients from "../data/clientdb";
import partner from "../data/partnerdb";

// -----------services--------------------------
import manageservices from "../assets/services/serv.png";
import consultation from "../assets/services/Untitled.png";
import vapt from "../assets/services/sec.png";
import softwaredev from "../assets/services/sf.png";

// -------------video----------------------------
import euodoovideo from "../assets/video/euodoo.mp4";

import { Link } from "react-router-dom";
import pasangmasda from "../assets/announcement/PASANGMASDA/pasangmasda.png"

import { useState,useEffect } from "react";
import axios from 'axios';
import { SERVER } from "../keyvariable";
import Countdown from 'react-countdown';
import phitex2023_a from "../assets/announcement/phitex2023.png";

const Homepage = ()=>{

    const [announcementcount, setannouncementcount] = useState([]);
    // const renderer = ({ days, hours, minutes, seconds }) => {

    //     const containerStyle = {
    //         background:" rgba(255, 255, 255, 0.2)", borderRadius: "50%", width:"90px" , height: "90px",
    //         boxShadow: "10px 10px 5px 1px rgba(108, 73, 124, 0.39)",
    //         padding:"1%",
    //         color: "#313B92"
    //     };
    //     return (
    //         <div>
    //             <div className="countdown justify-content-center row">
    //                 <div className="countdownitems col-6 mx-2" style={containerStyle}><b style={{fontSize:"27px",paddingTop:"50%"}}>{days}</b><br/><p style={{fontSize:"13px"}}>days</p></div>
    //                 <div className="countdownitems col-6 mx-2" style={containerStyle}><b style={{fontSize:"27px",paddingTop:"50%"}}>{hours}  </b><br/><p style={{fontSize:"13px"}}>hours</p></div>
    //                 <div className="countdownitems col-6 mx-2" style={containerStyle}><b style={{fontSize:"27px",paddingTop:"50%"}}>{minutes}</b><br/><p style={{fontSize:"13px"}}>min  </p></div>
    //                 <div className="countdownitems col-6 mx-2" style={containerStyle}><b style={{fontSize:"27px",paddingTop:"50%"}}>{seconds}</b><br/><p style={{fontSize:"13px"}}>sec  </p></div>
    //             </div>
    //             <h5 style={{color: "#313B92"}} className="py-5">We're thrilled to announce our upcoming Philippine Travel Exchange (PHITEX) 2023, an event that stands as a testament to the resilience and progress of the Philippine tourism industry. Building on the successes of PHITEX 2022 and MICECON 2023, we're gearing up for another remarkable gathering that promises to shape the future of travel and trade in the Philippines.</h5>
    //             <img className="w-100" src={phitex2023_a}/>
    //         </div>
    //     );
    //   };
    // const targetDate = new Date('2023-09-19T08:00:00');
    useEffect(()=>{

        axios.get(SERVER).then(res=>{
            setannouncementcount(res.data);
        }).catch(err => console.log(err))

    })

    const filtercolumn = ()=>{
        setannouncementcount(announcementcount.filter(announce => announce.status !== "inactive"))
    }
    return(
        <div className="">
            {
                
               announcementcount.length === 0 || announcementcount.length === null? "" :
                    <section className="announcement1 container">
                        <h1 className="mb-4 text-uppercase text-center aboutusourcompanyprofile">ANNOUNCEMENT</h1> 
                        {/* <div className="text-center py-5"> */}
                            {/* <img src={phitex2023}/> */}
                            {/* <Countdown date={targetDate} renderer={renderer} />
                        </div> */}
                        <div className="announcementrow row m-0 p-0 ">
                            {
                                announcementcount.map((announce, id)=>{
                                    return(
    
                                        <div key={id} className="col-12 col-md-6 col-lg-6 px-3 py-3"> 
                                            {
                                                    announce.status === "inactive" ? 
                                                  
                                                      filtercolumn()
                                                 
                                                :
                                                    announce.name === "pasangmasda.png" ? 
                                                    <Link to="/announceDetail">
                                                        <img src={`${SERVER}/uploads/${announce.name}`} className=" announcementimg" style={{width:"100%"}}/> 
                                                    </Link>
                                                :
                                                    announce.name === "fms.png" ? 
                                                    <Link to={`/fms-article/1`}>
                                                        <img src={`${SERVER}/uploads/${announce.name}`} className=" announcementimg" style={{width:"100%"}}/> 
                                                    </Link>
                                                :
                                                    announce.name === "fms2.png" ? 
                                                    <Link to={`/fms-article/2`}>
                                                        <img src={`${SERVER}/uploads/${announce.name}`} className=" announcementimg" style={{width:"100%"}}/> 
                                                    </Link>
                                                :
                                                    announce.name === "phcvs.png" ? 
                                                    <Link to={`/phcvs-article/3`}>
                                                        <img src={`${SERVER}/uploads/${announce.name}`} className=" announcementimg" style={{width:"100%"}}/> 
                                                    </Link>
                                                :
                                                    announce.name === "guidetofms_main.png" ? 
                                                    <Link to={`/guidetofms-article/4`}>
                                                        <img src={`${SERVER}/uploads/${announce.name}`} className=" announcementimg" style={{width:"100%"}}/> 
                                                    </Link>
                                                :
                                                    <img src={`${SERVER}/uploads/${announce.name}`} className="announcementimg" style={{width:"100%"}}/> 


                                            }
                                        </div>
                                        
                                    )

                                }).sort().reverse()
                            }

                        </div>
                    </section>

            }

            <section id="home" className="header-bg">
              <div className="hometext text-center">
                
                    <h3 className="text-uppercase buildingtech" id="building">Building  Technologies, <br/>Inspiring Innovations.</h3>
                    <p className="mx-5 fs-5 hometextp">
                        euodoó Technologies, Inc. way of providing service is anchored to excellence, innovation, and
                        commitment to its potential and existing clientele, thus nurturing long-standing business 
                        relationships. </p>
                        <Link to="/contactus" style={{textDecoration:"none", color: "#271a26"}}>
                            <button type="button" className="btn getintouch">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <b>LET'S GET IN TOUCH!</b>
                            </button>
                        </Link>
                        <Link to="/schedule" style={{textDecoration:"none", color: "#271a26"}}>
                            <button type="button" className="btn getintouch">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <b>SCHEDULE AN APPOINTMENT!</b>
                            </button>
                        </Link>
                        <br/>
                    <h3 className="mt-5 text-uppercase buildingtech">our milestones</h3><br/>
                    <div className="divimg">
                          <img src={milestone} className="milestonepic img-fluid" alt="s2018"/>
                    </div>
                  
                </div>
                <div className="area" >
                        <ul className="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                </div>
            </section>

            <section className="container" id="aboutus"> 
                <div className=" aboutuscontent  ">   
                    <h2 className=" text-uppercase text-center aboutusourcompanyprofile">Our Company Profile</h2>
                    <div className="aboutuscard card mt-3">
                        <div className="row p-0 m-0 g-0">
                            <div className="col-12 col-lg-4 text-center">
                                <img src={strategy} className=" aboutusimagestrat img-fluid" alt="company profile"/>
                                <img src={bayadcarddunsdict} className="aboutusimage img-fluid" alt="logo"/>   
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="card-body mt-3">
                                    
                                    <h3 className="text-uppercase aboutuscardtitle card-title ">How It All Started</h3>
                                    <p className="card-text aboutustext mx-3">
                                                euodoó Technologies, inc. is an outcome of more than fifteen (15) years of 
                                                extensive experience within the Information Technology (IT) Solutions and 
                                                Services industry. Formerly Innovare Tekhne, the spin-off company now carries 
                                                the Systems Integrator (SI) products and services while Innovare will focus on 
                                                IT-managed services. <br/> <br></br>

                                                The firm provides comprehensive IT Solutions focusing on areas such as 
                                                Enterprise Content Management (ECM), Enterprise Resource Planning (ERP), Governance, 
                                                Risk Management and Compliance (GRC), Anti-Money Laundering (AML) system, Integrated Cloud 
                                                School Management system, Human Resource Information System (HRIS), Automated Bus Ticketing 
                                                System (ABTS), customized software development and outsourced IT solutions and services, 
                                                both in public and private entities.                                    
                                    </p>
                                    <Link to="/aboutus" type="button" className="btn aboutusbtn mb-3"><b>See More</b></Link>
                                </div>
                            </div>
                        </div> 
                    </div>      
  
                </div>
            </section>
            <section id="featured">
                <div className="container pt-5">
                    <div className="text-center mb-5" >
                        <h2 className=" text-uppercase featuredtitle">
                            All in a day's work at Euodoo Tech
                        </h2>
                        <p className="featuredminititle">
                            <b> Find out more of the latest news and updates, all powered by euodoó Tech.</b>
                            <br/>
                            <Link to="/news">
                                <button type="button" className="btn featuredtitlebutn">
                                    <b>View More</b>
                                </button>
                            </Link>

                        </p>
                    </div>
                    <div className="featuredcard row g-3">
                        <div className=" col-12 col-md-6 col-lg-4">
                            <Link to="/fms-article/2" style={{textDecoration:"none", color:"black"}}>
                                <div className="card">
                                    <img src={fms2} className=" p-4 card-img-top" alt="news jeep"/>
                                    <div className="card-body">
                                        <h5 className="card-title">Stay Ahead of the Competition: A Foolproof Guide to Selecting the Right Fleet Management System</h5>
                                        <p className="card-text">
                                            Fleet management plays a crucial role in the ...
                                        </p>
                                        <div className="rbtn">
                                            <button type="button" className="btn aboutusbtn"><b>Read More</b></button>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className=" col-12 col-md-6 col-lg-4">
                            <Link to="/fms-article/1" style={{textDecoration:"none", color:"black"}}>
                                <div className="card">
                                    <img src={fms} className=" p-4 card-img-top" alt="news jeep"/>
                                    <div className="card-body">
                                        <h5 className="card-title">10 Game-Changing ways a fleet management system can skyrocket your business success!</h5>
                                        <p className="card-text">
                                            In today's fast-paced business world, efficent man...
                                        </p>
                                        <div className="rbtn">
                                            <button type="button" className="btn aboutusbtn"><b>Read More</b></button>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className=" col-12 col-md-6 col-lg-4">
                            <Link to="/announceDetail" style={{textDecoration:"none", color:"black"}}>
                                <div className="card">
                                    <img src={mainpic} className=" p-4 card-img-top" alt="news jeep"/>
                                    <div className="card-body">
                                        <h5 className="card-title">Euodoo joins pasangmasda Launching of new OFG compliant modern jeepney</h5>
                                        <p className="card-text">
                                            Last April 28, 2023, the Euodoo Technologies team...
                                        </p>
                                        <div className="rbtn">
                                            <button type="button" className="btn aboutusbtn"><b>Read More</b></button>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
                
             </section>
            <section id="client" className="my-20">
                <div>
                    {/* <img src={jeep} className="clientbus image-fluid w-25 position-relative start-50 translate-middle" alt="modern jeep"/> */}
                    <div className="clienttext container text-center  pb-5">
                        <h3 className=" clientheadtext">
                            We are greatful to work with various organizations and business
                        </h3>
                        <p>
                            At euodoo Technologies, Inc., we believe that your IT requirements should
                            be supported by experienced professionals, and offer a variety of services to cater
                            to your organization’s needs.
                        </p>
                    </div>

                    <div className="wrapper pb-5">
                        <div className="slider">
                            <div className="slide-track">
                            
                                <div className="slide">
                                    <img src={clients[0]} className="clientimg d-block" alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[1]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[2]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[3]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[4]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[5]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[6]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[7]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[8]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[9]} className="clientimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={clients[0]} className="clientimg d-block" alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[1]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[2]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[3]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[4]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[5]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={clients[6]} className="clientimg d-block " alt="..."/>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section id="partner">
                <div>
                    <div className="clienttext container text-center pb-5">
                        <h3 className="clientheadtext">
                            Our Strong Partnership             
                        </h3>
                        <p>
                            Organizations across a wide variety of industries have chosen euodoo Technologies, Inc., as their trusted IT partner.
                            Our outstanding reputation is built from many years of providing world-class service, and handling each technology need with care and precision.
                            Learn more about who we've helped and what we can provide for you.
                        </p>
                    </div>
                    <div className="wrapper">
                        <div className="slider">
                            <div className="slide-track">
                            
                                <div className="slide">
                                    <img src={partner[0]} className="partnerimg d-block" alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[1]} className="partnerimg d-block" alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[2]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[3]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[4]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[5]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[6]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[7]} className="partnerimg d-block" alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[8]} className="partnerimg d-block" alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[9]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[10]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[11]} className="partnerimg d-block " alt="..."/>
                                </div>
                                
                                <div className="slide">
                                    <img src={partner[12]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[13]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[14]} className="partnerimg d-block" alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[15]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[16]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[17]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[18]} className="partnerimg d-block " alt="..."/>
                                </div>
                                <div className="slide">
                                    <img src={partner[19]} className="partnerimg d-block " alt="..."/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section id="services">
                <div>
                    <div className="text-center mb-5" >
                        <h2 className=" text-uppercase featuredtitle">
                            What we offer
                        </h2>
                        <p className="featuredminititle">
                            <b> euodoó Tech offers comprehensive IT solutions and technical 
                                consulting services for various business function and industry-specific 
                                requirements</b>
                        </p>
                    </div>
                    <div className="container servicecardrow card-group">
                        <div className="card rounded mx-2" >
                            <img src={softwaredev} className="p-4 card-img-top" alt="..."/>
                            <div className="card-body">
                                <h6 className="card-text text-center">Software Development</h6>
                            </div>
                        </div>
                        <div className="card rounded mx-2">
                            <img src={consultation} className="p-4 card-img-top" alt="..."/>
                            <div className="card-body">
                                <h6 className="card-text text-center">Consultation</h6>
                            </div>
                        </div>
                        <div className="card rounded mx-2">
                            <img src={vapt} className="p-4 card-img-top" alt="..."/>
                            <div className="card-body">
                                <h6 className="card-text text-center">VAPT</h6>
                            </div>
                        </div>
                        <div className="card rounded mx-2">
                            <img src={manageservices} className="p-4 card-img-top" alt="..."/>
                            <div className="card-body">
                                <h6 className="card-text text-center">Managed Services</h6>
                            </div>
                        </div>
                    </div>
                    <Link to="/services">
                    
                        <button type="button" className="btn mt-4 mb-5"> 
                            <h5 className="p-2 servicetitlebutn position-absolute start-50 translate-middle-x">View More</h5> 
                        </button> 
                    </Link>
                </div>
               
            </section>
            <section id="readmore">
                <div className="row p-0 m-0">
                    <div className="col">
                        <video src={euodoovideo} className="rounded pt-5 mb-2 position-relative start-50 translate-middle-x" autoPlay playsInline loop muted></video>
                        {/* <ReactPlayer url='https://www.youtube.com/watch?v=FjE0eBJhzZM' className="pt-5 mb-2 position-relative start-50 translate-middle-x"  
                        playing={true} config={{ file: { attributes: { playsInline: true, }, }, }} loop muted style={{pointerEvents:"none", cursor:"default"}}/> */}
                   
                    </div>
                </div>
                <div className="text-center p-0 m-0 row">
                    <Link to="/package">
                        <button type="button" className=" readmorebtn text-uppercase ">
                            <b>Read more about our full package coverage </b>
                        </button>
                    </Link>

                </div>

            </section>
        </div>
        
    )
}

export default Homepage;